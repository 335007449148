body {
  font: 14px "Century Gothic", Futura, sans-serif;
  margin: 10px;
}

.invisible {
  visibility: hidden;
}

.spellable-root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.login-logo {
  padding-top: 25px;
  height: 130px;
  object-fit: contain;
}

.login-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 600px;
  height: auto;
  width: auto;
  padding-top: 20px;
}

.login-main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
}

.login-line {
  border-top: 1px solid #eee;
}

.login-footer {
  flex-shrink: 0;
}

.fast-login-tagline {
  font: 16px "Century Gothic", Futura, sans-serif;
  padding: 5px;
}

.fast-login-button {
  font: 16px "Century Gothic", Futura, sans-serif;
}

.login-tagline {
  font: 16px "Century Gothic", Futura, sans-serif;
  padding: 5px;
}

.login-tagline-bold {
  font: 16px "Century Gothic", Futura, sans-serif;
  font-weight: bold;
}

.login-detail-text {
  font: 14px "Century Gothic", Futura, sans-serif;
  padding-bottom: 10px;
}

.footer-item {
  padding-right: 30px;
  padding-left: 30px;
}

.spellable-input {
  font: 15px "Century Gothic", Futura, sans-serif;
  width: 300px;
}

.spellable-selector {
  font: 15px "Century Gothic", Futura, sans-serif;
  width: 300px;
}
.signup-container-div {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.signup-div {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 50px;
  gap: 5px;
  width: 400px;
}

.welcome-text {
  display: flex;
  justify-content: center;
  font-size: 28px;
  padding-top: 25px;
}

.welcome-subtext {
  display: flex;
  justify-content: center;
  font-size: 20px;
  padding-top: 20px;
}

.welcome-subtext2 {
  display: flex;
  justify-content: center;
  font-size: 16px;
  padding-top: 20px;
}

.upload {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.wordlist-widget-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 14px;
  padding-top: 35px;
}

.word-spelling {
  display: flex;
  justify-content: center;
  font-size: 30px;
  height: 40px;
}

.alternate-spellings {
  display: flex;
  justify-content: center;
  font-size: 12px;
  height: 15px;
}

.audio-buttons-row {
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: auto;
}

.audio-button-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.audio-button-div-hidden {
  display: none;
}

.audio-button-text {
  text-align: center;
  padding-top: 5px;
}

.audio-button {
  background-image: url('https://s3.amazonaws.com/thousandwords/speaker.png');
  background-size: 50px 50px;
  background-position: center;
  height: 50px;
  width: 50px;
}

.audio-change {
  background-image: url('https://s3.amazonaws.com/thousandwords/change2.png');
  background-size: 50px 50px;
  background-position: center;
  height: 50px;
  width: 50px;
}

.word-root {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  width: auto;
}

.word-root-div {
  font-weight: bold;
  height: auto;
  width: auto;
}

.landing-pane {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: auto;
}

.word-list {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: auto;
  padding-top: 15px;
  text-align: center;
}

.word-list-title {
  font-size: 14px;
  padding-bottom: 5px;
  align-items: center;
}

.word-list-item {
  font-size: 12px;
  align-items: center;
}

/* Must be used in conjunction with word-list-item */
.word-list-item-nested {
  color: gray;
  /* text-decoration: line-through; */
}

.word-meaning-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.word-meaning-heading {
  font-weight: bold;
}

.word-notes-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: auto;
  height: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.word-notes-heading {
  font-weight: bold;
}

.word-input {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: auto;
  margin-top: 20px;
}

.root-input {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
  padding-top: 15px;
  height: auto;
  width: auto;
}

.word-input-assess .answer-img {
  height: 20px;
  width: 20px;
  margin-left: 10px;
}

.generic-button-div {
  text-align: center;
  margin-top: 20px;
}

.generic-button {
  font: 14px "Century Gothic", Futura, sans-serif;
}

.spellable-score {
  text-align: center;
  margin-top: 20px;
  font: 14px "Century Gothic", Futura, sans-serif;
}

.spellable-score-bold {
  font-weight: bold;
}

.report-feedback-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 35px;
  color: gray;
  height: auto;
  width: auto;
}

.report-feedback {
}

.report-feedback-title {
  padding: 5px;
}

.report-feedback-entry {
  padding-left: 30px;
  padding-right: 10px;
}

.answer-img {
  height: 13px;
  width: 13px;
  margin-left: 10px;
  margin-right: 5px;
}

.round-radio {
  display: inline-block;
  position: relative;
  text-align: center;
  cursor: default;
  border-radius: 8px;
  line-height: 16px;
  padding: 5px;
  height: 16px;
  width: 16px;
  margin-right: 10px;
  border: 1px solid;
  font-weight: bold;
  background-color: white;
  color: black;
  border-color: gray;
}

.pressed {
  background-color: black;
  color: white;
}

.multiple-choice-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: auto;
  margin-left: 15px;
  margin-right: 15px;
  padding-top: 30px;
}

.multiple-choice-heading {
  font-weight: bold;
  padding-bottom: 10px;
}

.multiple-choice-entry {
  margin-left: 15px;
  padding: 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.multiple-choice-details {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.multiple-choice-text {
  font-weight: bold;
}

.highlight-answer {
  background-color: #ffffe0;
  border: 1px solid #808000;
}

.upload-link {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
}

.upload-img {
  height: 18px;
  width: 18px;
}

.collapsible {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.collapsible-title {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  font-size: 16px;
  font-weight: bold;
  padding-top: 25px;
  text-align: center;
  border: none;
  outline: none;
  transition: 0.4s;
}

.arrow-right {
  height: 15px;
  width: 15px;
  margin-left: 5px;
  transition: transform is linear;
}

.arrow-down {
  height: 15px;
  width: 15px;
  margin-left: 5px;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  transition: transform is linear;
}

.button-group-box {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  font: 14px "Century Gothic", Futura, sans-serif;
}

.button-group {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 10px;
  height: auto;
  font: 14px "Century Gothic", Futura, sans-serif;
}
 
.active-button {
  flex-grow: 1;
  background-color: gainsboro;
  font: 14px "Century Gothic", Futura, sans-serif;
  padding: 3px 5px;
  border-width: 1px;
}

.inactive-button {
  flex-grow: 1;
  background-color: white;
  font: 14px "Century Gothic", Futura, sans-serif;
  padding: 3px 5px;
  border-width: 1px;
} 

.list-details-box {
  width: 300px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid gray;
}

.list-header {
  background-color: gainsboro;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
}

.list-title {
  font: 14px "Century Gothic", Futura, sans-serif;
  padding: 5px;
  font-weight: bold;
  align-self: center;
}

.list-details {
  font: 14px "Century Gothic", Futura, sans-serif;
  padding-bottom: 5px;
  font-color: gray;
  align-self: center;
}

.list-details-checkbox {
  font: 14px "Century Gothic", Futura, sans-serif;
  padding-top: 7px;
  padding-bottom: 5px;
  font-color: gray;
  align-self: center;
}

.list-details-checkbox label input {
  margin-right: 5px;
  vertical-align: middle;
}

.list-mode-details-box {
  width: 300px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid gray;
}

.list-mode-title {
  font: 14px "Century Gothic", Futura, sans-serif;
  font-style: italic;
  padding: 5px;
  align-self: center;
}

.list-button-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.list-button-prefix {
  font: 14px "Century Gothic", Futura, sans-serif;
  align-items: center;
  justify-content: center;
}

.list-button {
  font: 14px "Century Gothic", Futura, sans-serif;
  margin-left: 5px;
  margin-right: 10px;
}

.lds-dual-ring-small {
  display: inline-block;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 40%;
  left: 50%;
  margin-left: -20px;
  margin-top: -20px;
}

.lds-dual-ring-small:after {
  content: " ";
  display: block;
  width: 32px;
  height: 32px;
  margin: 4px;
  border-radius: 50%;
  border: 3px solid #000;
  border-color: #000 transparent #000 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

.lds-dual-ring-big {
  display: inline-block;
  width: 80px;
  height: 80px;
  position: absolute;
  top: 40%;
  left: 50%;
  margin-left: -40px;
  margin-top: -40px;
}

.lds-dual-ring-big:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #000;
  border-color: #000 transparent #000 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.error-img {
  height: 20px;
  width: 20px;
  margin-left: 10px;
  margin-right: 5px;
}

.error-screen {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  width: 500px;
  margin-top: 100px;
  margin-left: 30%;
}

.error-msg {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center; 
  font: 16px "Century Gothic", Futura, sans-serif;
}

.retry-button {
  margin: auto;
  font: 14px "Century Gothic", Futura, sans-serif;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100px;
}

.error-word-info {
  color: gray;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  padding-top: 10px;
  padding-bottom: 10px;
}

.error-word {
  text-align: center;
}

.error-meaning {
  text-align: center;
}

.footer-item a {
  color: black;
  text-decoration: none;
}

.footer-item a:hover, a:active {
  text-decoration: underline;
}

.step-button {
  font: 16px "Century Gothic", Futura, sans-serif;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #007FFF;
  color: white;
}

.step-button:disabled {
  opacity: 0.35;
  cursor: not-allowed;
}
